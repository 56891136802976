<template>
  <div class="index " :class="{'home-bg' : screenType=='0'}">
    <div class="header">
      <div class="header-time">
        <span class="nickname">{{ userInfo.nickName }}</span>
        <span class="time-h">{{ date.hours }}:{{ date.minutes }}:{{ date.seconds }}</span>
        <span class="time-d">{{ date.year }}年{{ date.month }}月{{ date.date }}日 {{ date.week }} {{ weather }}</span>
      </div>
      <div class="header-risk">
        <div class="header-risk-item" v-if="screenType!='0'&&screenType!='2'">
          <div class="risk-t">较大风险</div>
          <div class="risk-s">风险预警</div>
        </div>
      </div>
      <div class="header-title" >
        <span class="title-text">{{ titleText }}</span>
        <img class="img-robot" :src="robotImg" @click="showChat()"/>
      </div>
      <div class="header-home">
        <div class="header-home-item" @click="backToHome()" v-if="screenType!='0'">
          <i class="el-icon-s-home"/>
          <span>首页</span>
        </div>

      </div>
      <div class="header-guardian">
        <div class="guardian-d">
          <span style="font-size: 0.5rem">25</span>
          <span style="font-size: 0.25rem">天</span>
        </div>
        <div class="guardian-t">安全守护</div>
      </div>
    </div>
    <router-view/>
    <div class="chat animate__animated animate__zoomIn" v-if="isShowChat" v-loading="isRecording"
         element-loading-text="点击窗口外部结束录音">
      <JwChat-index :config="config" :showRightBox='false' scrollType="scroll" :taleList="taleList" @enter="bindEnter"
                    @clickTalk="talkEvent" v-model="inputMsg" :toolConfig="tool">
        <template slot="tools">
          <el-button class="tool-btn-start" type="primary" size="mini" @click="recording"
                     icon="el-icon-microphone"></el-button>
          <el-button type="info" size="mini" @click="pause">停止播报</el-button>
          <el-button type="info" size="mini" @click="clearChat">重新开始</el-button>
        </template>
        <template slot="header">
          <el-button size="mini" @click="hideChat" icon="el-icon-close" circle></el-button>
        </template>
      </JwChat-index>
    </div>
    <div class="mask" v-if="isShowChat" @click="endRecording"></div>
  </div>
</template>

<script>
import {jsonp} from 'vue-jsonp'
import {mapState} from 'vuex'
import utils from "@/utils/utils";
import {baiduAi, voiceToMsg} from "@/api/screen";
import Recorder from "js-audio-recorder";


export default {
  name: "index",
  data() {
    return {
      robotImg: require('@/assets/images/robot.png'),
      weather: '晴',
      timer: null,
      date: {
        year: null,
        month: null,
        date: null,
        week: null,
        hours: null,
        minutes: null,
        seconds: null
      },

      recorder: null,
      isRecording: false,

      isShowChat: false,
      session_id: '',
      inputMsg: '',
      taleList: [],
      tool: {
        showEmoji: false,
      },

      config: {
        img: require('@/assets/images/robot.png'),
        name: '云放小安',
        // quickList: [
        //   {text: '快捷提示1'},
        //   {text: '快捷提示2'},
        //   {text: '快捷提示3'},
        // ]
      },
    }
  },
  computed: {
    ...mapState(['screenType', 'userInfo']),
    titleText() {
      let text = '云放科技应急AI管理平台'
      switch (this.screenType) {
        case '0':
          text = '云放科技应急AI管理平台'
          break
        case '1':
          text = '云放科技应急AI管理平台企业辅助模式'
          break
        case '2':
          text = '云放科技应急AI管理平台地图模式'
          break
        case '3':
          text = '云放科技应急AI管理平台政府监管模式'
          break
      }
      return text
    },

  },
  mounted() {
    this.getTime()
    this.getWeather()
    this.player = new Audio()
  },

  methods: {
    //初始化录音
    initRender() {
      this.recorder = new Recorder({
        sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
      });
    },

    pause() {
      this.player.pause()
    },

    //开始录音
    recording() {
      this.player.pause()
      this.initRender();
      this.recorder.start();
      this.isRecording = true;
    },

    //结束录音
    endRecording() {
      this.recorder.stop();
      this.isRecording = false;
      this.parseVoice()
    },

    reqBaiduAi(words) {
      //对话框消息-用户发出的消息
      let mineMsg = {
        mine: true,
        name: "用户",
        date: utils.formatTime(new Date()),
        img: require('@/assets/images/user.png'),
        text: {
          text: words
        },
      }
      this.taleList.push(mineMsg)

      //百度AI请求数据
      let data = {
        version: '3.0',
        service_id: 'S70428',
        log_id: new Date().getTime(),
        session_id: this.session_id,
        request: {
          terminal_id: this.userInfo.userId,
          query: words,
        }
      }
      baiduAi(data).then(res => {
        this.session_id = res.result.session_id
        let readMsg = ''

        //对话框消息-AI返回的消息
        let robotMsg = {
          mine: false,
          name: "云放小安",
          date: utils.formatTime(new Date()),
          img: require('@/assets/images/robot.png'),
          text: {},
        }
        if (res.result.responses[0].actions[0].type == 'guide') {
          let resContent = []
          readMsg = res.result.responses[0].actions[0].say
          for (let i = 0; i < res.result.responses[0].actions[0].options.length; i++) {
            resContent.push({
              id: i,
              text:(i+1)+'.'+res.result.responses[0].actions[0].options[i].option,
              msg:res.result.responses[0].actions[0].options[i].option,
            })
            readMsg = readMsg + ',' + res.result.responses[0].actions[0].options[i].option
          }
          robotMsg.text = {
            system: {
              title: res.result.responses[0].actions[0].say,
              content: resContent
            }
          }
        } else if (res.result.responses[0].actions[0].type == 'satisfy') {
          robotMsg.text = {
            text: res.result.responses[0].actions[0].say
          }
          readMsg = res.result.responses[0].actions[0].say
        } else if (res.result.responses[0].actions[0].type == 'clarify') {
          robotMsg.text = {
            text: res.result.responses[0].actions[0].say
          }
          readMsg = res.result.responses[0].actions[0].say
        }
        console.log(robotMsg)
        this.taleList.push(robotMsg)
        console.log(readMsg)

        this.player.pause()
        this.player.src = '/api/yuying?msg=' + readMsg
        this.player.play()
      })
    },

    parseVoice() {
      let fromData = new FormData();
      fromData.append("file", this.recorder.getWAVBlob())
      voiceToMsg(fromData).then(res => {
        if (res.result[0] != '') {
          let resWords = res.result[0].slice(0, res.result[0].length - 1)
          this.reqBaiduAi(resWords)
        } else {
          this.$message({
            message: '请重新录音',
            type: 'warning'
          });
        }
      })
    },

    getWeather() {
      // eslint-disable-next-line no-undef
      const ip = returnCitySN['cip']  //获取当前ip
      const key = '2518b8592b4949328e3d9b3bf4ae9f1d'
      jsonp('https://restapi.amap.com/v3/ip', {  //根据获取当前位置
        key: key,
        ip: ip
      }).then(res => {
        jsonp('https://restapi.amap.com/v3/weather/weatherInfo', { //根据位置获取天气
          city: res.adcode,
          extensions: 'base',
          key: key
        }).then(res => {
          this.weather = res.lives[0].weather
        })
      })
    },
    backToHome() {
      this.$router.push('/home')
    },

    getTime() {
      this.timer = setInterval(() => {
        let now = new Date();
        this.date.year = now.getFullYear(); // 2015, 年份
        this.date.month = this.formatZero(now.getMonth() + 1); // 5, 月份，注意月份范围是0~11，5表示六月
        this.date.date = this.formatZero(now.getDate()); // 24, 表示24号
        // this.date.week = now.getDay(); // 3, 表示星期三
        this.date.week = this.formatWeek(now.getDay()); // 3, 表示星期三
        this.date.hours = this.formatZero(now.getHours()); // 19, 24小时制
        this.date.minutes = this.formatZero(now.getMinutes()); // 49, 分钟
        this.date.seconds = this.formatZero(now.getSeconds()); // 22, 秒
      }, 1000)
    },

    formatZero(param) {
      return param.toString().padStart(2, '0')
    },

    formatWeek(param) {
      let week = ''
      switch (param) {
        case 1:
          week = '周一';
          break
        case 2:
          week = '周二';
          break
        case 3:
          week = '周三';
          break
        case 4:
          week = '周四';
          break
        case 5:
          week = '周五';
          break
        case 6:
          week = '周六';
          break
        case 0:
          week = '周日';
          break
      }
      return week
    },

    showChat() {
      this.isShowChat = true
      this.session_id=''
      this.bindEnter('小安')
    },

    hideChat() {
      this.isShowChat = false
      this.player.pause()
    },

    clearChat() {
      this.session_id = ''
      this.bindEnter('小安')
      // this.taleList = []
    },

    //输入框点击就发送或者回车触发的事件
    bindEnter(msg) {
      const queryMsg = msg ? msg : this.inputMsg
      if (!queryMsg || queryMsg == '\n') return;
      this.reqBaiduAi(queryMsg)
    },

    talkEvent(e) {
      this.bindEnter(e.data.msg)
    },

  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="scss">
.index {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-bg {
  background: url("~@/assets/images/bg2.png");
  background-size: 100% 100%;
  position: fixed;
  height: 100%;
  width: 100%;
}

.header {
  color: #FDFDFF;
  height: 1rem;
  display: flex;
  align-items: center;
}

.header-time {
  height: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .nickname {
    font-size: 0.2rem;
  }

  .time-h {
    font-size: 0.25rem;
  }

  .time-d {
    font-size: 0.2rem;
  }
}

.header-risk {
  height: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-risk-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .risk-t {
      font-size: 0.375rem;
      color: #F0DE4E;
    }

    .risk-s {
      font-size: 0.25rem;
    }
  }
}

.header-title {
  height: 1rem;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .title-text {
    font-size: 0.375rem;
    letter-spacing: 0.0625rem;
  }

  .img-robot{
    height: 0.5rem;
    cursor: pointer;
  }
}

.header-home {
  height: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .header-home-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > i {
      font-size: 0.375rem;
    }

    & > span {
      margin-left: 0.125rem;
      font-size: 0.25rem;
    }
  }


}

.header-guardian {
  height: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .guardian-d {
    display: table-cell;
    vertical-align: bottom;
  }

  .guardian-t {
    font-size: 0.25rem;
  }
}

.chat {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

::v-deep .web__main {
  width: 100% !important;
  padding-right: 0.5rem !important;;
}

::v-deep .downBtn {
  display: none !important;
}

.ChatPage {
  ::v-deep .header {
    background-color: #BB73E4 !important;
    justify-content: space-between;
    padding: 0 0.15rem !important;
  }
}

::v-deep .el-button--primary {
  background-color: #BB73E4 !important;
  border-color: #BB73E4 !important;
}


::v-deep .web__main-item--mine {
  .web__main-text {
    background-color: #BB73E4 !important;
  }

  .web__main-arrow {
    border-left-color: #BB73E4 !important;
  }

  .web__main-arrow:after {
    border-left-color: #BB73E4 !important;
  }
}

::v-deep .toolsBox {
  padding: 0 0.125rem;
  justify-content: flex-end;
}

.tool-btn-start {
  margin-right: auto;
}

::v-deep .web__msg {
  padding: 0.125rem !important;
}
</style>
