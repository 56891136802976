// Echars中字体大小适配
function FontChart(res) {
    let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (!clientWidth) return; //报错拦截：
    let fontSize = (clientWidth / 1920);
    return res * fontSize;
}

// 格式化时间为YYYY/MM/DD HH:mm:ss
function formatTime(date) {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    const second = date.getSeconds().toString().padStart(2, '0')

    return [year, month, day].join('/') + ' ' + [hour, minute, second].join(':')
}

// 格式化空值
function formatNull(value) {
    if (value == null || value == undefined || value == '' ) {
        return '-'
    } else {
        return value
    }
}

module.exports = {
    FontChart: FontChart,
    formatTime: formatTime,
    formatNull: formatNull
}
