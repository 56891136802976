import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/screen/index";

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    {
        path: '/',
        name: 'login',
        component: () => import( '@/views/login/login')
    },
    {
        path: '/home',
        component: Index,
        children: [{
            path: "",
            name: "home",
            component: (resolve) => require(["@/views/screen/home"], resolve)
        }]
    },
    {
        path: '/company',
        component: Index,
        children: [{
            path: "company",
            name: "company",
            component: (resolve) => require(["@/views/screen/company/company"], resolve)
        }]
    },
    {
        path: '/government',
        component: Index,
        children: [{
            path: "government",
            name: "government",
            component: (resolve) => require(["@/views/screen/government/government"], resolve)
        }]
    },
    {
        path: '/plat',
        component: Index,
        children: [{
            path: "plat",
            name: "plat",
            component: (resolve) => require(["@/views/screen/plat/plat"], resolve)
        }]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
