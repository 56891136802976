import request from "@/utils/request";

// 地图
export function mapDp() {
    return request({
        url: '/api/mapDp',
        method: 'get'
    })
}

// 政府
export function zfDp() {
    return request({
        url: '/api/zfDp',
        method: 'get'
    })
}

// 企业
export function companyDp() {
    return request({
        url: '/api/companyDp',
        method: 'get'
    })
}


// 百度AI
export function baiduAi(data) {
    return request({
        url: '/rpc/2.0/unit/service/v3/chat?access_token=24.db25ba478d38cee45cd439ff6a420145.2592000.1657382436.282335-26414033',
        method: 'post',
        data: data
    })
}

// 文字转语音
export function msgToVoice(params) {
    return request({
        url: '/api/yuying',
        method: 'get',
        params:params

    })
}

// 语音转文字
export function voiceToMsg(data) {
    return request({
        url: '/api/fanyi',
        method: 'post',
        data: data
    })
}
