import Vue from 'vue'
import Vuex from 'vuex'
import {login, logout} from '@/api/login'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        reducer: (state) => {
            return {
                userInfo: state.userInfo,
                screenType: state.screenType
            }
        }
    })],
    state: {
        userInfo: '',
        screenType: '0'
    },
    getters: {},
    mutations: {
        SetUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        RemoveUserInfo(state) {
            state.userInfo = ''
        },
        SetScreenType(state, screenType) {
            state.screenType = screenType
        },
    },
    actions: {
        Login({commit}, data) {
            return new Promise((resolve, reject) => {
                login(data).then(res => {
                    if (res.success == '1') {
                        commit('SetUserInfo', res.result.user)
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        LogOut(store, data) {
            return logout(data).then(res => {
                console.log(res)
                store.commit('RemoveUserInfo')
            })
        },

        changeScreenType(store, data) {
            store.commit('SetScreenType', data)
        }
    },
    modules: {}
})
