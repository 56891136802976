import request from "@/utils/request";

//登录
export function login(data) {
    return request({
        url: '/api/login',
        method: 'post',
        data: data
    })
}

// 登出
export function logout(data) {
    return request({
        url: '/api/login/loginOut',
        method: 'post',
        data: data
    })
}
