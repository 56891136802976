import axios from "axios";

const request = axios.create({
    // baseURL: 'http://192.168.31.183:9011',
    // baseURL: '/api',
    timeout: 60000
})

//请求拦截器
request.interceptors.request.use(config => {
        return config
    },
    error => {
        // return Promise.reject(error)
        console.log(error)
    }
)

//相应拦截器
request.interceptors.response.use(res => {
        return res.data
    },
    error => {
        // return Promise.reject(error)
        console.log(error)
    }
)

export default request

